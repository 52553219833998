*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-size: 10pt;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 3rem);
  margin-top: 3rem;
}

main {
  flex: 1;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
}